import React from 'react'
import styled from 'styled-components';
import device from '../utils/device';

const StyledErrorLabel = styled.span`
  color: ${ ({ theme }) => theme.colors.redBorder };
  font-size: 10px;
  float: right;
  text-align: right;
  
  @media ${device.laptop} {
    position: absolute;
    right: 10px;
    background-color: ${ ({ theme }) => theme.colors.primaryWhite };
    top: -7px;
    padding: 0 3px;
    z-index: 9999;
  }
`

const ErrorLabel = ({ children }) => {
  return (
    <StyledErrorLabel>{children}</StyledErrorLabel>
  )
}

export default ErrorLabel
