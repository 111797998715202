import styled, { css } from 'styled-components';
import device from '../../utils/device';
import blur from 'assets/images/form-blur_bg.jpg';

const OfferFormWrapper = styled.form`
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding-left: ${({ theme }) => theme.dimensions.padding.mainLeftPadding};
  padding-right: ${({ theme }) => theme.dimensions.padding.mainLeftPadding};
  margin-bottom: 40px;
  background-color: ${({ theme }) => theme.colors.primaryWhite};

  @media ${device.laptop} {
    margin-bottom: 0;
    padding-bottom: 40px;
    z-index: 9999;
    width: calc(0.75 * 522px);

    ${({ absolute }) =>
      absolute &&
      css`
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 10vw;
        width: calc(0.75 * 522px);
        padding-top: 0;
      `}
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  
  @media ${device.laptopL} {
    width: 480px;
    ${({ absolute }) =>
      absolute &&
      css`
    width: 480px;
  `}
  }
  
  @media ${device.desktop} {
    width: 522px;
    padding: 0 30px;
    padding-bottom: 80px;

    ${({ absolute }) =>
      absolute &&
      css`
    width: 522px;
  `}
  }
  
  @media ${device.desktopL} {
    width: 622px;
    ${({ absolute }) =>
      absolute &&
      css`
      padding-top: 100px;
      width: 622px;
    `}
  }
`;

const BlurArea = styled.div`
  display: none;

  @media ${device.laptop} {
    ${({ absolute }) =>
      absolute &&
      css`
        position: absolute;
        top: 0;
        right: 10vw;
        min-height: 100vh;
        width: calc(0.75 * 522px);
        padding-top: 2vh;
        display: block;
        backdrop-filter: blur(4px);
        background: url(${blur}) center center / cover;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          min-height: 100vh;
          width: calc(0.75 * 522px);
          background-color: ${({ theme }) => theme.colors.primaryBlack};
          opacity: 0.3;
        }
      `}
  }
  
    @media ${device.laptopL} {
    ${({ absolute }) => (
  absolute && css`
        width: 480px;
        &:before {
          width: 480px;
        }
      `
)}
  }
  
  @media ${device.desktop} {
    ${({ absolute }) => (
      absolute && css`
        width: 522px;
        &:before {
          width: 522px;
        }
      `  
    )}
  }
  
    @media ${device.desktopL} {
    ${({ absolute }) => (
  absolute && css`
        width: 622px;
        &:before {
          width: 622px;
        }
      `
)}
  }
`;

export default {
  OfferFormWrapper,
  BlurArea,
};
