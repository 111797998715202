import React from 'react';
import styled from 'styled-components';
import backgroundImage from 'assets/images/hero-background.jpg';
import device from '../../utils/device';
import LanguageSwitch from '../../components/LanguageSwitch';

const ImageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 420px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 420px;
    background-color: ${({ theme }) => theme.colors.primaryBlack};
    opacity: 0.3;
  }
    
  @media ${device.laptop} {
    min-height: 100vh;
    /* background-size: 200% 150%; */
    /* background-position: -60vw -35vh; */
    background-position: center center;
    position: absolute;
    display: flex;
    
    &:before {
      height: 100%;
    }
   
  }
  
  
`;

const HeroImage = () => {
  return (
    <ImageWrapper>
      <LanguageSwitch/>
    </ImageWrapper>
  )
};
export default HeroImage;
