import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import content from '../../utils/content';
import constants from '../../utils/constants';
import device from '../../utils/device';


const Label = styled.span`
  background-color: ${ ({ theme, status }) => status === constants.formStatuses.SUCCESS ? theme.colors.successGreen : theme.colors.redBorder };
  color: ${ ({ theme }) => theme.colors.primaryWhite };
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 10px;
  text-align: center;
  display: ${ ({ visible }) => visible ? 'block' : 'none' };
  
  @media ${device.laptop} {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
`

const OfferFormActionStatus = ({ status, message }) => {

  const [ visible, setVisible ] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, 5000)
  })

  return (
    <Label visible={visible} status={status}>{message}</Label>
  )
}

OfferFormActionStatus.propTypes = {
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default OfferFormActionStatus
