import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RequiredStar from 'assets/images/required-star.svg';
import constants from '../utils/constants';
import ErrorLabel from './ErrorLabel';
import withTranslations from '../utils/withTranslations';
import content from '../utils/content';
import device from '../utils/device';

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  font-size: 18px;
  border: 1px solid ${({ theme, error }) => error ? theme.colors.redBorder : theme.colors.secondaryGray};
  margin-bottom: 10px;
  width: 100%;
  padding: 20px 10px 20px 10px;

  &:focus,
  &:not(:placeholder-shown) {
    ~ div {
      transform: scale(0.8) translateY(-40px);
      background-color: ${({ theme }) => theme.colors.primaryWhite};
      padding-left: 10px;
      padding-right: 5px;

      @media ${device.laptop} {
        transform: scale(0.8) translateY(-30px);
      }

      @media ${device.laptopL} { 
        transform: scale(0.8) translateY(-40px);
      }
    }
  }
  
  @media ${device.laptop} {
    padding: 10px 20px;
  }

  @media ${device.laptopL} {
    padding: 10px 25px 15px;
  }
  
  @media ${device.desktop} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  /* @media ${device.desktopL} {
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }  */
`;

const LabelWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  transition: all 0.1s linear;

  @media ${device.laptop} {
    left: 20px;
  }

  @media ${device.laptopL} {
    left: 25px;
  }
`;

const StyledLabel = styled.span`
  padding-top: 10px;
  position: relative;
  color: ${({ theme }) => theme.colors.inputLabelColor};
  font-size: 18px;
  
  @media ${device.laptop} {
    padding-top: 8px;
    font-size: 12px;
  }
  
  @media ${device.laptopL}{
    padding-top: 5px;
    font-size: 18px;
  }
  
  @media ${device.desktop} {
    font-size: 20px;
    line-height: 45px;
    padding-top: 0 !important;
  }
  
  @media ${device.desktopL} {
    font-size: 24px;
  }
/* 
  @media ${device.laptop} and (min-height: 768px) {
    padding-top: 10px;
  } */
`;

const StyledRequiredStar = styled.img`
  margin-bottom: 10px;
  display: inline-block;
  margin-left: 10px;
`;

const TextInput = ({ placeholder, required, type, onChange, isFormValid, t }) => {

  const [ error, setError ] = useState(null);

  const [ value, setValue ] = useState('')

  useEffect(() => {
  	if (!isFormValid) {
  	  setError(t(content.inputErrors.EMPTY))
    } else {
  	  setError(null)
    }
  }, [isFormValid])



  const onInputChange = (e) => {
    const text = e.target.value

    if (!text.length) {
      setValue(text)
      onChange(text)
      if (!isFormValid) {
        setError(t(content.inputErrors.EMPTY))
      } else {
        setError(null)
      }
    } else {
      setError(null)
    }

    if (type === constants.inputTypes.EMAIL) {
        if (constants.EMAIL_REGEX.test(text)) {
          if (text.length) {
            setError(null)
          } else {
            setError(t(content.inputErrors.EMPTY))
          }
        } else {
          setError(t(content.inputErrors.EMAIL));
        }
        setValue(text)
        onChange(text)
    } else if (type === constants.inputTypes.PHONE) {
      if (constants.PHONE_REGEX.test(text) && text.length < 16) {
        setValue(text)
        onChange(text)
      }
    } else {
      setValue(text)
      onChange(text)
    }

  }

  const onBlur = () => {
    if (type === constants.inputTypes.EMAIL) {
      if (!constants.EMAIL_REGEX.test(value) && value.length) {
        setError(t(content.inputErrors.EMAIL))
      } else if (!value.length && !isFormValid) {
        setError(t(content.inputErrors.EMPTY))
      } else {
        setError(null)
      }
    }

  }


  return (
    <StyledWrapper>
      { error && <ErrorLabel>{error}</ErrorLabel>}
      <StyledInput
        placeholder={' '}
        error={error}
        onChange={onInputChange}
        onBlur={onBlur}
        value={value}
      />
      <LabelWrapper>
        <StyledLabel>{placeholder}</StyledLabel>
        {required && <StyledRequiredStar src={RequiredStar} alt="required" />}
      </LabelWrapper>
    </StyledWrapper>
  );
};

TextInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  isFormValid: PropTypes.bool,
};

TextInput.defaultProps = {
  required: false,
  type: constants.inputTypes.TEXT,
  onChange: f => f,
  isFormValid: true
};

export default withTranslations(TextInput);
