import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import facebook from 'assets/images/facebook.svg';
import linkedin from 'assets/images/linkedin.svg';
import device from '../utils/device';

const ICONS = {
  facebook,
  linkedin,
};

const StyledWrapper = styled.div`
  padding-right: 15px;
`;

const StyledIcon = styled.img`
  height: 20px;
  width: 20px;
  
  @media ${device.desktop} {
    height: 30px;
    width: 30px;
  }
  
  @media ${device.desktopL} {
    height: 40px;
    width: 40px;
  }
`;

const IconLink = ({ icon, url }) => {
  return (
    <StyledWrapper>
      <a href={url} target="_blank">
        <StyledIcon src={ICONS[icon]} alt={icon} />
      </a>
    </StyledWrapper>
  );
};

export default IconLink;

IconLink.propTypes = {
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

IconLink.defaultProps = {};
