import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import device from '../../utils/device';

const StyledAddressLine = styled.p`
  margin: 0;
  padding: 5px 0;
  font-size: 15px;
  letter-spacing: 0.68px;

  @media ${device.laptop} {
    font-size: 12px;
  }
  
  @media ${device.laptopL} {
    font-size: 14px;
    line-height: 24px;
  }
  
  @media ${device.desktop} {
    font-size: 14px;
    line-height: 24px;
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 14px);
    line-height: calc(1.3 * 24px);
  }
`;

const FooterAddressLine = ({ content }) => {
  return <StyledAddressLine>{content}</StyledAddressLine>;
};

FooterAddressLine.propTypes = {
  content: PropTypes.string.isRequired,
};

export default FooterAddressLine;
