import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import device from '../../utils/device';

const StyledFooterLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryWhite};
  text-decoration: none;
  padding-bottom: 5px;
  margin-right: 65px;
  display: block;
  line-height: 20px;
  cursor: pointer;
  font-size: ${({ l }) => (l ? '17px' : '15px')};
  letter-spacing: ${({ l }) => (l ? '0.77px' : '0.68px')};
  
  @media ${device.laptop} {
    font-size: 12px;
    line-height: 18px;
  }
  
  @media ${device.laptopL} {
    font-size: 14px;
    line-height: 24px;
  }
  
  @media ${device.desktop} {
    font-size: 14px;
    line-height: 24px;
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 14px);
    line-height: calc(1.3 * 24px);
  }
`;

const FooterLink = ({ url, label, l, equal }) => {
  return (
    <StyledFooterLink href={url} l={l} equal={equal} target="_blank">
      {label}
    </StyledFooterLink>
  );
};

FooterLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  l: PropTypes.bool,
};

FooterLink.defaultProps = {
  l: false,
};

export default FooterLink;
