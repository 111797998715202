import React, { useState } from 'react'
import styled, { css } from 'styled-components';
import constants from '../utils/constants';
import i18next from 'i18next';
import device from '../utils/device';
import withTranslations from '../utils/withTranslations';

const StyledWrapper = styled.div`
  float: right;
  margin: ${ ({ theme }) => theme.dimensions.padding.mainLeftPadding };
  margin-right: 0;
  padding-top: 20px;
  position: relative;
  z-index: 1000;
  
  @media ${device.laptop} {
    margin-top: 0;
    float: none;
    margin-left: 92vw;
    z-index: 9999;
  }
`

const LanguageButton = styled.span`
  color: ${ ({ theme }) => theme.colors.primaryWhite };
  font-size: 20px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  font-weight: ${ ({ active }) => active ? 'bold' : 'normal' };
  position: relative;
  padding-right: 20px;
  cursor: pointer;
  
  &:before {
    content: '';
    height: 4px;
    width: 70%;
    position: absolute;
    bottom: -3px;
    left: 0;
    display: ${ ({ active }) => active ? 'block' : 'none' };
    background-color: ${ ({ theme }) => theme.colors.redBorder };
  }
  
  ${({ last }) => (
    !last && css`
      &:after {
        content: '/';
        position: absolute;
        top: 0;
        right: 5px;
      }
    `
  )}
  
  @media ${device.laptop} {
    font-size: 14px;
    letter-spacing: 1px;
  }
  
  @media ${device.desktop} {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.45px;
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 22px);
    line-height: calc(1.3 * 30px);
    letter-spacing: calc(1.3 * 0.45px);
  }
`

const LanguageSwitch = ( { t, language, i18n }) => {


  const [ chosenLanguage, setChosenLanguage ] = useState(language)

  const changeLanguage = (lng) => {
    if (!constants.LANGUAGE_DOMAINS[lng]) {
      return;
    }
    window.location = `https://${constants.LANGUAGE_DOMAINS[lng]}`;
    // i18n.changeLanguage(lng);
    // setChosenLanguage(lng)
    // localStorage.setItem('language', lng);
  }

  return (
    <StyledWrapper>
      {constants.LANGUAGES.map((lang, index) => (
        <LanguageButton
          onClick={() => changeLanguage(lang)}
          last={index === constants.LANGUAGES.length - 1}
          active={chosenLanguage === lang}
          key={index}>
          {lang}
        </LanguageButton>
      ))}
    </StyledWrapper>
  )
}

export default withTranslations(LanguageSwitch);
