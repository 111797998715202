import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OfferFormComponents from './OfferForm.style';
import OfferFormHeader from './OfferFormHeader';
import InputsSection from './InputsSection';
import ConsentSection from './ConsentSection';
import Button from '../../components/Button';
import content from '../../utils/content';
import OfferFormActionStatus from './OfferFormActionStatus';
import constants from '../../utils/constants';
import fetch from 'node-fetch';
import withTranslations from '../../utils/withTranslations';

const OfferForm = ({ absolute, t }) => {
  const [ formStatus, setFormStatus ] = useState(null)

  const [ message, setMessage ] = useState('')

  const [ isFormValid, setIsFormValid ] = useState(true)
  const basicFormData = {
    name: null, surname: null, email: null, phone: null,
    location: null, consents: {
      RODO: null,
      DATA_PROCESSING: null,
      TRADE_INFO: null,
      MOBILE_MARKETING: null,
      PRIVACY_POLICY: null
    }
  }
  const [ formData, setFormData ] = useState(basicFormData);

  const handleInputChange = (text, field) => {
    console.log(text, field)
    formData[field] = text
    setFormData(formData)
  }

  const handleConsent = (value, field) => {
    console.log(value, field)
    formData.consents[field] = value;
    setFormData(formData)
  }

  const handleFormStatus = (status, message) => {
    setFormStatus(status)
    setMessage(message)
    setTimeout(() => {
      setFormStatus(null)
    }, 5000)
  }

  const validateForm = () => {
    const requiredFields = constants.REQUIRED_FIELDS
    const requiredConsents = constants.REQUIRED_CONSENTS
    let isValid = true

    for (let key in formData) {
      if (key === 'consents') {
        for (let consent in formData[key]) {
          if (requiredConsents.includes(key) && !formData.consents[key]) {
            if (isValid) {
              isValid = false
            }
          }
        }
      } else {
        if (requiredFields.includes(key) && !formData[key]?.length) {
          if (isValid) {
            isValid = false
          }
        }
      }
    }
    setIsFormValid(false)
    return isValid
  }

  const submitOfferForm = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    let lang = constants.COMPILATION_FALLBACK_LANGUAGE;

    if (typeof window !== 'undefined') {
      lang = String(window.location.pathname).indexOf('/en') === 0 ? 'en' : 'pl';
    }

    // const language = await localStorage.getItem('language')
    // TODO Remove console log and 'return' statement in order to make form alive
    // In order to change CONTACT_FORM_URL refer to constants file
    const isValid = validateForm()
    if (!isValid) {
      console.log('form invalid');
      return;
    }

    const data = formData
    data.language = lang;

    fetch(constants.CONTACT_FORM_URL, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(res => {
        if (res.response) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event' : 'wyslij',
            'formularz' : 'wyslano',
          });
          handleFormStatus(constants.formStatuses.SUCCESS, res.message);
          setIsFormValid(true);
          setFormData(basicFormData);

        } else {
          handleFormStatus(constants.formStatuses.ERROR, res.message)
        }

      })
      .catch(() => handleFormStatus(constants.formStatuses.ERROR, t(content.formStatuses.ERROR)))
  }

  return (
    <>
      <OfferFormComponents.BlurArea absolute={absolute} />
      <OfferFormComponents.OfferFormWrapper absolute={absolute} id='offer-form'>
        { formStatus && <OfferFormActionStatus status={formStatus} message={message} /> }
        <OfferFormHeader absolute={absolute} />
        <InputsSection handleInputChange={handleInputChange} isFormValid={isFormValid} />
        <ConsentSection absolute={absolute} handleConsent={handleConsent} isFormValid={isFormValid} />
        <Button title={t(content.SEND)} onClick={submitOfferForm} />
      </OfferFormComponents.OfferFormWrapper>
    </>
  );
};

OfferForm.propTypes = {
  absolute: PropTypes.bool,
};

OfferForm.defaultProps = {
  absolute: false,
};

export default withTranslations(OfferForm);
