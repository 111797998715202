export default {
  CITY_FLEX: 'CITY_FLEX',
  LAST_MILE_LOGISTICS: 'LAST_MILE_LOGISTICS',
  HERO_HEADER: 'HERO_HEADER',
  HERO_SUBHEADER: 'HERO_SUBHEADER',
  HERO_SUBHEADER_BOLD: 'HERO_SUBHEADER_BOLD',
  FILL_FORM: 'FILL_FORM',
  LOGO_ALT: 'LOGO_ALT',
  SEE_OUR_OFFER: 'SEE_OUR_OFFER',
  FILL_FORM_INFO: 'FILL_FORM_INFO',
  NAME: 'NAME',
  SURNAME: 'SURNAME',
  EMAIL: 'EMAIL',
  PHONE_NUMBER: 'PHONE_NUMBER',
  LOCALIZATION: 'LOCALIZATION',
  CHOSE_FROM_THE_LIST_HINT: 'CHOSE_FROM_THE_LIST_HINT',
  RODO_CONSENT: 'RODO_CONSENT',
  DATA_PROCESSING_CONSENT: 'DATA_PROCESSING_CONSENT',
  TRADE_INFO_CONSENT: 'TRADE_INFO_CONSENT',
  MOBILE_MARKETING_CONSENT: 'MOBILE_MARKETING_CONSENT',
  PRIVACY_POLICY_CONSENT: 'PRIVACY_POLICY_CONSENT',
  SEND: 'SEND',
  COMPANY_DESCRIPTION_BOLD: 'COMPANY_DESCRIPTION_BOLD',
  COMPANY_DESCRIPTION_NORMAL: 'COMPANY_DESCRIPTION_NORMAL',
  SECTIONS: [
    {
      title: 'SECTION_1.title',
      body:
        'SECTION_1.body',
      image: 'runner',
    },
    {
      title: 'SECTION_2.title',
      body:
        'SECTION_2.body',
      image: 'tennis',
    },
    {
      title: 'SECTION_3.title',
      body:
        'SECTION_3.body',
      image: 'bike',
      light: true,
    },
    {
      title: 'SECTION_4.title',
      body:
        'SECTION_4.body',
      image: 'strongman',
    },
    {
      title: 'SECTION_5.title',
      body:
        'SECTION_5.body',
      image: 'acrobat',
    },
  ],
  COMPANY_DESCRIPTION_SECONDARY: 'COMPANY_DESCRIPTION_SECONDARY',
  FOOTER: {
    WHAT_WE_DO: 'FOOTER.WHAT_WE_DO',
    LINKS: [
      {
        label: "FOOTER.LINK_1.label",
        url: "FOOTER.LINK_1.url"
      },
      {
        label: "FOOTER.LINK_2.label",
        url: "FOOTER.LINK_2.url"
      },
      {
        label: "FOOTER.LINK_3.label",
        url: "FOOTER.LINK_3.url"
      },
    ],
    CONTACT_WITH_US: 'FOOTER.CONTACT_WITH_US',
    EMAIL: 'FOOTER.EMAIL',
    ADDRESS: ['7R SA', 'ul. Ludwinowska 7', '30-331 Kraków', 'tel: +48 12 427 30 64'],
    FOLLOW_US: 'FOOTER.FOLLOW_US',
    COPYRIGHT: 'FOOTER.COPYRIGHT',
    COPYRIGHT_LINK: 'FOOTER.COPYRIGHT_LINK',
    COOKIES_POLICY: 'FOOTER.COOKIES_POLICY',
    COOKIES_POLICY_LINK: 'FOOTER.COOKIES_POLICY_LINK',
    PRIVACY_POLICY: 'FOOTER.PRIVACY_POLICY',
    PRIVACY_POLICY_LINK: 'FOOTER.PRIVACY_POLICY_LINK',
  },
  formStatuses: {
    SUCCESS: "formStatuses.SUCCESS",
    ERROR: 'formStatuses.ERROR'
  },
  inputErrors: {
    EMAIL: "inputErrors.EMAIL",
    PHONE: "inputErrors.PHONE",
    EMPTY: "inputErrors.EMPTY"
  }
}
