import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Logo from '../components/Logo';
import device from '../utils/device';

const LogoWrapper = styled.div`
  position: relative;
  padding: 25px 20px;

  @media ${device.laptop} {
    ${({ medium }) =>
      medium &&
      css`
        padding-left: 0;
      `}

    ${({ header }) =>
      header &&
      css`
        position: absolute;
        top: 220px;
        left: 9vw;
      `}
  }
  
  @media ${device.desktopL} {
    ${({ header }) => (
        header && css`
              top: 550px;
            `
      )}
  }
  
  @media ${device.desktopL} {
    ${({ header }) => (
      header && css`
        top: 500px;
      `  
    )}
  }
`;

const LogoSection = ({ large, xLarge, medium, header }) => {
  return (
    <LogoWrapper header={header} medium={medium}>
      <Logo large={large} xLarge={xLarge} medium={medium} header={header} />
    </LogoWrapper>
  );
};

LogoWrapper.propTypes = {
  medium: PropTypes.bool,
  large: PropTypes.bool,
  xLarge: PropTypes.bool,
  header: PropTypes.bool,
};

LogoWrapper.defaultProps = {
  medium: false,
  large: false,
  xLarge: false,
  header: false,
};

export default LogoSection;
