import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DecorationTilesRight from 'assets/images/decoration-tiles-right.svg';
import DecorationTilesRightTernary from 'assets/images/decoration-tiles-right-ternary.svg';
import content from '../../utils/content';
import LogoSection from '../LogoSection';
import device from '../../utils/device';
import withTranslations from '../../utils/withTranslations';

const StyledWrapperPrimary = styled.div`
  padding-left: ${({ theme }) => theme.dimensions.padding.mainLeftPadding};
  padding-bottom: 100px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 50px;
    right: 0;
    background-image: url(${DecorationTilesRight});
    height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    overflow: visible;
    
    @media ${device.laptop} {
      background-size: 50%;
      height: 300px;
      bottom: -50px;
    }
  }

  @media ${device.laptop} {
    position: relative;
    padding-top: 10vh;
    width: 50vw;
  }
`;

const DescriptionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.descriptionBackground};
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding: 40px 40px 40px
    ${({ theme }) => theme.dimensions.padding.mainLeftPadding};
  text-align: center;
  font-size: 20px;

  p {
    line-height: 30px;
  }

  @media ${device.laptop} {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    text-align: left;
    padding-right: 10vw;
    padding-left: 80px;
    font-size: 14px;
    p {
      line-height: 20px;    
    }
  }
  
  @media ${device.laptopL} {
      font-size: 20px;
      line-height: 30px;
  }
  
  @media ${device.desktop} {
    p {
      font-size: 25px;
      line-height: 38px;
    }

  }
  
  @media ${device.desktopL} {
    p {
      font-size: calc(1.3 * 25px);
      line-height: calc(1.3 * 38px);
    }

  }
`;

const StyledWrapperSecondary = styled.div`
  padding: 75px 0 75px
    ${({ theme }) => theme.dimensions.padding.mainLeftPadding};
  background-color: ${({ theme }) => theme.colors.descriptionBackground};
  margin-top: 63px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -130px;
    right: 0;
    background-image: url(${DecorationTilesRightTernary});
    height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    overflow: visible;
    z-index: 9999;
  }
  
  @media ${device.laptop} {
    margin-right: 3vw;
    margin-top: 0;
    margin-left: 0;
    padding-bottom: 0;
    min-width: 40vw;

    &:after {
      display: none;
    }
  }
  
  @media ${device.laptopL} {
    margin-right: 5vw;
    margin-left: 5vw;
  }
  
  @media ${device.desktop} {
    margin-left: 5vw;
  }
  
  
`;

const SecondaryContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  font-size: 18px;
  padding-bottom: 40px;

  p {
    padding: 0 60px 0 45px;
    text-align: center;
    line-height: 30px;
  }

  @media ${device.laptop} {
    font-size: 25px;
    padding: 15vh 2vw 1vh 8vw;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    height: calc(0.53 * 733px);
    width: calc(0.53 * 938px);
    

    p {
      text-align: left;
      padding-left: 0;
      font-size: 14px;
      line-height: 19px;
    }
  }
  
  @media ${device.laptopL} {
    width: calc(0.75 * 938px);
    height: calc(0.75 * 733px);
    
    p {
      font-size: calc(0.75 * 25px);
      line-height: calc(0.75 * 38px);
    }
  }
  
  @media ${device.desktop} {
    width: 938px;
    height: 733px;
    
    p {
      font-size: 25px;
      line-height: 38px;
    }
  }
  
 
  @media ${device.desktopL} {
    width: calc(1.3 * 938px);
    height: calc(1.3 * 733px);
    
    p {
      font-size: calc(1.3 * 25px);
      line-height: calc(1.3 * 38px);
    }
  }
`;

const CompanyDescription = ({ primary, t }) => {
  if (primary) {
    return (
      <StyledWrapperPrimary>
        <DescriptionContainer>
          <p>
            <strong>{t(content.COMPANY_DESCRIPTION_BOLD)}</strong>
            {t(content.COMPANY_DESCRIPTION_NORMAL)}
          </p>
        </DescriptionContainer>
      </StyledWrapperPrimary>
    );
  }
  return (
    <StyledWrapperSecondary>
      <SecondaryContainer>
        <LogoSection xLarge />
        <p>{t(content.COMPANY_DESCRIPTION_SECONDARY)}</p>
      </SecondaryContainer>
    </StyledWrapperSecondary>
  );
};

CompanyDescription.propTypes = {
  primary: PropTypes.bool,
};

CompanyDescription.defaultProps = {
  primary: false,
};
export default withTranslations(CompanyDescription)
