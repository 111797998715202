import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import device from '../utils/device';

const CheckboxWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  margin-bottom: 10px;
  
  ${({ isValid }) => (
    !isValid && css`
      border: 2px solid ${ ({ theme }) => theme.colors.redBorder };
      /* border-left: none; */
    `   
  )}
  
`;

const CheckboxLabel = styled.label`
  font-size: 12px;
  letter-spacing: 0.54px;
  padding-left: 30px;
  z-index: 9999;
  cursor: pointer;

  &:before {
    content: '';
    border: 1px solid ${({ theme }) => theme.colors.secondaryGray};
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    border: 1px solid ${({ theme }) => theme.colors.primaryBlack};
    border-left: none;
    border-top: none;
    opacity: 1;
    position: absolute;
    display: none;
    content: '';
    top: 0;
    left: 5px;
    transform: rotate(45deg);
    height: 15px;
    width: 10px;
  }

  @media ${device.laptop} {
    color: ${({ theme, absolute }) =>
      absolute ? theme.colors.primaryWhite : theme.colors.primaryBlack};
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.45px;
    

    &:before,
    &:after {
      border-color: ${({ theme, absolute }) =>
        absolute ? theme.colors.primaryWhite : theme.colors.primaryBlack};
    }
  }
  
  @media ${device.desktop} {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.45px;
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 10px);
    line-height: calc(1.3 * 16px);
    letter-spacing: calc(1.3 * 0.45px);
  }
  
`;

const CheckboxInput = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  position: absolute;
  padding: 0;

  &:checked {
    ~ label::before {
      border-width: 2px;
    }

    ~ label::after {
      display: block;
    }
  }
`;

const Checkbox = ({ htmlKey, name, label, absolute, handleConsent, required, isFormValid }) => {

  const [ isValid, setIsValid ] = useState(true)
  const [ checked, setChecked ] = useState(false)

  useEffect(() => {
    if (required && !checked) {
      setIsValid(isFormValid)
    }

  }, [isFormValid])

  const handleOnChange = (e) => {
    handleConsent(e.target.checked, name)
    setChecked(e.target.checked)
    if (required && !e.target.checked) {
      setIsValid(isFormValid)
    } else if (required && e.target.checked) {
      setIsValid(true)
    }
  }

  return (
    <CheckboxWrapper absolute={absolute} isValid={isValid}>
      <CheckboxInput type="checkbox" name={name} id={htmlKey} onChange={e => handleOnChange(e, name) }/>
      <CheckboxLabel htmlFor={htmlKey} absolute={absolute}>
        {label}
      </CheckboxLabel>
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  htmlKey: PropTypes.string.isRequired,
  absolute: PropTypes.bool,
  isFormValid: PropTypes.bool
};

Checkbox.defaultProps = {
  absolute: false,
  isFormValid: true
};

export default Checkbox;
