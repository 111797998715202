import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DecorationTilesLeft from 'assets/images/decoration-tiles-left.svg';
import DecorationTilesRight from 'assets/images/decoration-tiles-right-secondary.svg';
import device from '../utils/device';

const LeftDecorationWrapper = styled.div`
  width: 100%;
  height: 75px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -39px;
    left: 0;
    background-image: url(${DecorationTilesLeft});
    background-repeat: no-repeat;
    overflow: visible;
    width: 100%;
    height: 115px;
    
    @media ${device.laptop} {
      height: 200px;
      background-size: 20%;
      top: -50px;
    }
    
    @media ${device.desktop} {
      height: 220px;
      top: -100px;
    }
  }
`;

const RightDecorationWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  width: 100%;
  height: 20px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background-image: url(${DecorationTilesRight});
    background-repeat: no-repeat;
    overflow: visible;
    width: 100%;
    height: 124px;
    top: -104px;
    right: -120px;
    
    @media ${device.laptop} {
      background-position: right center;
      height: 220px;
      background-size: 25%;
      right: 0;
      top: -140px;
    }
    
    @media ${device.laptopL} {
      top: -135px;
    }
    
    @media ${device.desktop} {
      height: 260px;
      top: -200px;
    }
    

  }
`;

const Decoration = ({ left }) => {
  if (left) {
    return <LeftDecorationWrapper />;
  }
  return <RightDecorationWrapper />;
};

Decoration.propTypes = {
  left: PropTypes.bool,
};

Decoration.defaultProps = {
  left: false,
};

export default Decoration;
