import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DecorationTilesRightTernary from 'assets/images/decoration-tiles-right-ternary.svg';
import device from '../utils/device';

const StyledWrapper = styled.div`
  position: relative;

  @media ${device.laptop} {
    display: flex;
    flex-flow: row nowrap;
    background-color: ${({ theme }) => theme.colors.descriptionBackground};
    padding-right: 8vw;
    margin-top: 100px;

    &:after {
      content: '';
      position: absolute;
      top: -115px;
      right: 0;
      background-image: url(${DecorationTilesRightTernary});
      height: 170px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: right bottom;
      overflow: visible;
      background-size: 20%;
    }
  }
`;

const FormDescriptionWrapper = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

FormDescriptionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
export default FormDescriptionWrapper;
