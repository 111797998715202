import React from 'react';
import styled from 'styled-components';
import FooterDecorationTiles from 'assets/images/footer-decoration-tiles.svg';
import content from '../../utils/content';
import IconLink from '../../components/IconLink';
import FooterLink from './FooterLink';
import FooterHeader from './FooterHeader';
import FooterAddressLine from './FooterAddressLine';
import device from '../../utils/device';
import withTranslations from '../../utils/withTranslations';

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlack};
  color: ${({ theme }) => theme.colors.primaryWhite};
  padding-left: ${({ theme }) => theme.dimensions.padding.mainLeftPadding};
  padding-bottom: 40px;
  padding-top: 40px;

  @media ${device.laptop} {
    height: 40vh;
    padding-left: 10vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  
  @media ${device.desktop} {
    padding-top: 80px;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 30px;
`;

const FooterDecorationWrapper = styled.div`
  position: relative;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.descriptionBackground};

  &:before {
    content: '';
    position: absolute;
    top: 22px;
    left: 0;
    width: 100%;
    height: 120px;
    background-image: url(${FooterDecorationTiles});
    background-repeat: no-repeat;
    
  }

  @media ${device.laptop} {
    height: 1px;

    &:before {
      top: -80px;
      background-size: 20%;
      height: 160px;
    }
  }
  
  @media ${device.desktop} {
    top: -40px;
  }
`;

const BottomLinksContainer = styled.div`
  @media ${device.laptop} {
    display: flex;
    flex-flow: row nowrap;
  }
  
  @media ${device.desktop} {
    
    a {
      width: 10%;
    }
  }
`;

const MainContainer = styled.div`
  @media ${device.laptop} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  @media ${device.laptop} {
    display: flex;
    flex-flow: column nowrap;
    min-width: 30%;
  }
`;

const FooterLinksWrapper = styled.div`
  @media ${device.laptop} {
    padding-right: 0;
  }
  @media ${device.laptopL} {
    padding-right: 30%;
  }
`

const footer = content.FOOTER;

const Footer = ({ t }) => {
  return (
    <>
      <FooterDecorationWrapper />
      <StyledWrapper>
        <MainContainer>
          <Container>
            <FooterHeader content={t(footer.WHAT_WE_DO)} />
            <FooterLinksWrapper>
              {footer.LINKS.map(link => (
                <FooterLink url={t(link.url)} label={t(link.label)} key={t(link.label)} />
              ))}
            </FooterLinksWrapper>
          </Container>
          <Container>
            <FooterHeader content={t(footer.CONTACT_WITH_US)} />
            <FooterLink
              url={'mailto:' + t(footer.EMAIL)}
              label={t(footer.EMAIL)}
              key={footer.EMAIL}
              l
              equal
              target="_blank"
            />
            {footer.ADDRESS.map(addressLine => (
              <FooterAddressLine key={addressLine} content={addressLine} />
            ))}
          </Container>
          <Container>
            <FooterHeader content={t(footer.FOLLOW_US)} />
            <IconsWrapper>
              <IconLink icon="facebook" url='https://www.facebook.com/7rmagazyny/' />
              <IconLink icon="linkedin" url='https://www.linkedin.com/company/7r/' />
            </IconsWrapper>
          </Container>
        </MainContainer>

        <BottomLinksContainer>
          <FooterLink
            url={t(footer.COPYRIGHT_LINK)}
            label={t(footer.COPYRIGHT)}
            key={footer.COPYRIGHT}
            l
            equal
            target="_blank"
          />
          <FooterLink
            url={t(footer.COOKIES_POLICY_LINK)}
            label={t(footer.COOKIES_POLICY)}
            key={footer.COOKIES_POLICY}
            l
            equal
            target="_blank"
          />
          <FooterLink
            url={t(footer.PRIVACY_POLICY_LINK)}
            label={t(footer.PRIVACY_POLICY)}
            key={footer.PRIVACY_POLICY}
            l
            equal
            target="_blank"
          />
        </BottomLinksContainer>
      </StyledWrapper>
    </>
  );
};

export default withTranslations(Footer);

Footer.propTypes = {};

Footer.defaultProps = {};
