import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import device from '../../utils/device';

const StyledFooterHeader = styled.h4`
  text-transform: uppercase;
  padding: 20px 0;
  margin: 0;
  font-size: 17px;
  letter-spacing: 0.77px;

  @media ${device.laptop} {
    font-size: 12px;
    line-height: 20px;
  }
  
  @media ${device.laptopL} {
    font-size: 15px;
    line-height: 24px;
  }
  
  @media ${device.desktop} {
    font-size: 15px;
    line-height: 24px;
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 15px);
    line-height: calc(1.3 * 24px);
  }
`;

const FooterHeader = ({ content }) => {
  return <StyledFooterHeader>{content}</StyledFooterHeader>;
};

FooterHeader.propTypes = {
  content: PropTypes.string.isRequired,
};

export default FooterHeader;
