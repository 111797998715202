import React from 'react';
import styled from 'styled-components';
import content from '../../utils/content';
import ContentTile from '../../components/ContentTile';
import Decoration from '../../components/Decoration';
import withTranslations from '../../utils/withTranslations';

const { SECTIONS } = content;

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ContentTilesSection = ({ t }) => {
  return (
    <StyledWrapper>
      {SECTIONS.map((section, index) => (
        <React.Fragment key={t(section.title)}>
          {(index === 2 || index === 3) && <Decoration left={index === 2} />}
          <ContentTile
            index={index}
            title={t(section.title)}
            body={t(section.body)}
            image={section.image}
            light={section.light}
          />
        </React.Fragment>
      ))}
    </StyledWrapper>
  );
};

export default withTranslations(ContentTilesSection);

ContentTilesSection.propTypes = {};

ContentTilesSection.defaultProps = {};
