 import React from 'react';
import styled from 'styled-components';
import content from '../../utils/content';
import device from '../../utils/device';
import withTranslations from '../../utils/withTranslations';

const Subheader = styled.h3`
  position: relative;
  font-weight: normal;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primaryWhite};
  padding: 42px 60px 42px ${({ theme }) => theme.dimensions.padding.mainLeftPadding};
  margin: 0;
  line-height: 1.4;

  @media ${device.laptop} {
    position: absolute;
    top: 480px;
    left: 9vw;
    width: 600px;
    padding-right: 190px;
    font-size: 18px;
    padding-top: 40px;
  }
  
  @media ${device.laptopL} {
    padding-top: 20px;
    top: 450px;
    width: 600px;
    font-size: calc(0.75 * 25px);
  }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
  
   @media ${device.desktop} {
    font-size: 25px;
    width: 750px;
    top: 525px;
    /* line-height: 38px; */
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 25px);
    line-height: calc(1.3 * 38px);
    top: 850px;
    width: calc(1.3 * 750px);
  }
`;

const HeroSubheader = ({ t }) => {
  return (
    <Subheader>
      {t(content.HERO_SUBHEADER)}&nbsp;<strong>{t(content.HERO_SUBHEADER_BOLD)}</strong>
    </Subheader>
  );
};
export default withTranslations(HeroSubheader);
