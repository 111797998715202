import React from 'react';
import styled from 'styled-components';
// import content from '../../utils/content';
import device from '../../utils/device';
import withTranslations from '../../utils/withTranslations';

const HeaderWrapper = styled.div`
  position: relative;
  border-width: 5px 5px 5px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.redBorder};
  width: calc(100% - 20px);
  padding: 20px 60px 20px 20px;
  box-shadow: 0 0 10px 0px rgba(0,0,0, 0.4), inset 0 0 10px 0px rgba(0,0,0,0.4);
  margin-top: 20px;

  @media ${device.laptop} {
    position: absolute;
    top: 350px;
    left: 9vw;
    border-left-width: 5px;
    width: 440px;
    padding-left: 40px;
    padding-right: 40px;
    transform: translateX(-24px);
    margin-top: 0;
  }
  
  @media ${device.laptopL} {
    width: 550px;
  }
  
  @media ${device.desktop} {
    display: flex;
    align-items: center;
    width: 696px;
    height: 160px;
  }
  
  @media ${device.desktopL} {
    top: 650px;
    width: calc(1.3 * 696px);
    height: calc*(1.3 * 186px);
  }
`;

const Header = styled.h1`
  font-size: 38px;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.primaryWhite};
  
  @media ${device.laptop} {
    font-size: 36px;
  }
  
  @media ${device.desktop} {
    font-size: 50px;
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 50px);
  }
  

`;

const HeroHeader = ({ t }) => {
  return (
    <HeaderWrapper>
      <Header>{t('HERO_HEADER')}</Header>
    </HeaderWrapper>
  );
};
export default withTranslations(HeroHeader);
