import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '../../components/Checkbox';
import content from '../../utils/content';
import ConsentOption from '../../models/ConsentOption';
import constants from '../../utils/constants';
import withTranslations from '../../utils/withTranslations';
import device from '../../utils/device';

const StyledWrapper = styled.div`
  margin: 15px 0 40px;
  
  @media ${device.laptop} {
    margin-bottom: 10px;
  }
`;

const consentOptions = [
  new ConsentOption({
    name: constants.consents.RODO,
    label: content.RODO_CONSENT,
  }),
  new ConsentOption({
    name: constants.consents.DATA_PROCESSING,
    label: content.DATA_PROCESSING_CONSENT,
  }),
  new ConsentOption({
    name: constants.consents.TRADE_INFO,
    label: content.TRADE_INFO_CONSENT,
  }),
  new ConsentOption({
    name: constants.consents.MOBILE_MARKETING,
    label: content.MOBILE_MARKETING_CONSENT,
  }),
  new ConsentOption({
    name: constants.consents.PRIVACY_POLICY,
    label: content.PRIVACY_POLICY_CONSENT,
  }),
];

const ConsentSection = ({ absolute, handleConsent, isFormValid, t }) => {
  const key = (name, index) => {
    return absolute ? `${name}__${index}` : name;
  };
  return (
    <StyledWrapper>
      {consentOptions.map((option, index) => (
        <Checkbox
          key={key(option.name, index)}
          label={t(option.label)}
          name={option.name}
          required={option.required}
          handleConsent={handleConsent}
          absolute={absolute}
          htmlKey={key(option.name, index)}
          isFormValid={isFormValid}
        />
      ))}
    </StyledWrapper>
  );
};

ConsentSection.propTypes = {
  absolute: PropTypes.bool,
  isFormValid: PropTypes.bool
};
ConsentSection.defaultProps = {
  absolute: false,
  isFormValid: true
};

export default withTranslations(ConsentSection);
