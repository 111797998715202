import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import constants from '../utils/constants';
import ErrorLabel from './ErrorLabel';
import withTranslations from '../utils/withTranslations';
import content from '../utils/content';
import device from '../utils/device';

const StyledWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryWhite};
  border: 1px solid ${({ theme, error }) => error ? theme.colors.redBorder : 'transparent'};
`;

const InputWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondaryGray};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const Label = styled.span`
  padding: 20px 10px 20px 10px;
  color: ${({ theme, chosenOption }) =>
    chosenOption ? theme.colors.primaryBlack : theme.colors.inputLabelColor};
  font-size: 18px;
  
  @media ${device.laptop} {
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 10px;
    line-height: 20px;
    padding-left: 20px;
  }
  
  @media ${device.laptopL}{
    font-size: 18px;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  
  @media ${device.desktop} {
    font-size: 20px;
    line-height: 45px;
    padding-top: 10px;
  }
  
  @media ${device.desktopL} {
    font-size: 24px;
    padding-bottom: 20px;
    /* padding-top: 30px; */
  }
`;

const DropdownButton = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primaryGray};
  height: 60px;
  width: 60px;
  margin: 2px 0;
  position: relative;
  cursor: pointer;
  
  @media ${device.laptop} {
    height: 40px;
    width: 40px;
  }

  @media ${device.laptopL} {
    height: 50px;
    width: 50px;
  }
  
  @media ${device.desktop}{
    height: 60px;
    width: 60px;
  }

  &::after {
    cursor: pointer;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
    height: 12px;
    width: 12px;
    border: 3px solid ${({ theme }) => theme.colors.dropdownButtonArrowColor};
    border-top: none;
    border-left: none;
  }

  &:target {
    height: 100px;
    overflow: visible;
  }
`;

const OptionsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondaryGray};
  border-top: none;
  width: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  max-height: ${({ visible }) => (visible ? '200px' : 0)};
  transition: all 0.2s linear;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const Option = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  border: 1px solid ${({ theme }) => theme.colors.primaryGray};
  padding: 20px 10px;

  @media ${device.laptop} {
    padding-left: 15px;
  }

  @media ${device.laptopL} {
    left: 20px;
  }
`;
const ClearButton = styled.span`
  color: ${({ theme }) => theme.colors.primaryWhite};
  background-color: ${({ theme }) => theme.colors.secondaryGray};
  height: 14px;
  width: 14px;
  position: relative;
  border-radius: 50%;
  align-self: center;
  margin-right: 10px;

  &:before,
  &:after {
    content: '';
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    display: block;
    position: absolute;
    width: 50%;
    height: 1px;
    top: 50%;
    left: 50%;
  }

  &:before {
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
  }

  &:after {
    transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
`

const Dropdown = ({ placeholder, onChange, required, isFormValid, t }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [chosenOption, setChosenOption] = useState(null);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    if (!isFormValid) {
      setError(t(content.inputErrors.EMPTY))
    } else {
      setError(null)
    }
  }, [isFormValid])

  const OPTIONS = constants.dropdownOptions;

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const chooseOption = option => {
    setChosenOption(option);
    onChange(option, 'location')
    setIsDropdownVisible(false);
    setError(null)
  };

  const clearSelectedOption = () => {
    setChosenOption(null);
    onChange(null, 'location')
  }

  return (
    <Container>
      { error && <ErrorLabel>{error}</ErrorLabel>}
      <StyledWrapper tabindex={0} required={required} error={error}>
      <InputWrapper>
        <Label chosenOption={chosenOption}>{chosenOption || placeholder}</Label>
        {chosenOption && <ClearButton onClick={clearSelectedOption} />}
        <DropdownButton onClick={toggleDropdown} />
      </InputWrapper>
      <OptionsWrapper visible={isDropdownVisible}>
        {Object.values(OPTIONS).map(option => (
          <Option key={option} onClick={() => chooseOption(option)}>
            {option}
          </Option>
        ))}
      </OptionsWrapper>
    </StyledWrapper>
    </Container>
  );
};

Dropdown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

Dropdown.defaultProps = {
  onChange: f=>f,
  required: false,
}

export default withTranslations(Dropdown);
