import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import OfferForm from '../templates/OfferForm/OfferForm';
import LogoSection from '../templates/LogoSection';
import CompanyDescription from '../templates/CompanyDescription/CompanyDescription';
import Footer from '../templates/Footer/Footer';
import ContentTilesSection from '../templates/ContentTilesSection/ContentTilesSection';
import PageHeader from '../templates/PageHeader/PageHeader';
import DescriptionWrapper from '../components/DescriptionWrapper';
import FormDescriptionWrapper from '../components/FormDescriptionWrapper';

const HomePage = () => (
  <Layout>
    <SEO title="Home" />
    <PageHeader />
    <DescriptionWrapper>
      <LogoSection xLarge />
      <CompanyDescription primary />
    </DescriptionWrapper>

    <ContentTilesSection />
    <FormDescriptionWrapper>
      <CompanyDescription />
      <OfferForm />
    </FormDescriptionWrapper>

    <Footer />
  </Layout>
);

export default HomePage;
