import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import device from '../utils/device';

const StyledButton = styled.a`
  padding: 15px 10px;
  margin: 0 20px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: ${({ theme, primary }) => primary ? theme.colors.redBorder : theme.colors.primaryWhite};
  background-color: ${({ theme, primary }) => primary ? theme.colors.primaryWhite : theme.colors.primaryRed};
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: 5px;
    right: 5px;
    border-color: ${({ theme, primary }) => primary ? theme.colors.redBorder : theme.colors.primaryWhite};
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-width: 0 0 20px 20px;
  }
  
  @media ${device.laptop} {
    margin: 10px 0 0;
  }

  @media ${device.laptopL} {
    padding: 15px 10px;
  }

  @media ${device.desktop} {
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 0.45px;
    padding: 20px 10px;
  }
`;

const Button = ({ title, primary, url, onClick }) => {
  if (url) {
    return (
      <StyledButton href={url} primary={primary}>
        {title}
      </StyledButton>
    )
  }
  return (
    <StyledButton primary={primary} onClick={onClick}>
      {title}
    </StyledButton>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  url: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  primary: false,
  url: null,
  onClick: f=>f
};

export default Button;
