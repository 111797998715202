import React from 'react';
import styled from 'styled-components';
import DecorationTiles from 'assets/images/hero-decoration-tiles-desktop.svg';
import HeroImage from './HeroImage';
import HeroHeader from './HeroHeader';
import HeroSubheader from './HeroSubheader';
import HeroButton from './HeroButton';
import LogoSection from '../LogoSection';
import device from '../../utils/device';

import LanguageSwitch from '../../components/LanguageSwitch';
import backgroundImage from 'assets/images/hero-background.jpg';

const HeroWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryBlack};
  background-image: url(${backgroundImage});
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 87px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 31, 38, 0.75);
    z-index: 0;

    @media ${device.laptop} {
      content: '';
      display: none;
    }
  }
  
  @media ${device.laptop} {
    position: relative;
    min-height: 100vh;
    
    &:after {
      content: '';
      bottom: -103px;
      z-index: 9999;
      overflow: visible;
      left: 0;
      background-image: url(${DecorationTiles});
      background-size: 100%;
      position: absolute;
      height: 210px;
      width: 400px;
    }
  }
  
  @media ${device.desktop} {
    &:after {
      height: 320px;
      width: 600px;
      bottom: -160px;
    }
  }
 
`;

const Hero = () => {
  return (
    <HeroWrapper>
      {/* <HeroImage /> */}
      <LanguageSwitch/>
      <LogoSection header header />
      <HeroHeader />
      <HeroSubheader />
      <HeroButton />
    </HeroWrapper>
  );
};

export default Hero;
