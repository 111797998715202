import React from 'react';
import styled from 'styled-components';
import content from '../../utils/content';
import Button from '../../components/Button';
import device from '../../utils/device';
import withTranslations from '../../utils/withTranslations';

const ButtonWrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;

  @media ${device.laptop} {
    display: none;
  }
`;

const HeroButton = ({ t }) => {
  return (
    <ButtonWrapper>
      <Button title={t(content.FILL_FORM)} primary url='#offer-form' />
    </ButtonWrapper>
  );
};
export default withTranslations(HeroButton);
