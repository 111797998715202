import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import runner from 'assets/images/runner.jpg';
import tennis from 'assets/images/tennis.jpg';
import bike from 'assets/images/bike.jpg';
import strongman from 'assets/images/strongman.jpg';
import acrobat from 'assets/images/acrobat.jpg';
import device from '../utils/device';

const SECTION_IMAGES = {
  runner,
  tennis,
  bike,
  strongman,
  acrobat,
};

const StyledWrapper = styled.div`
  @media ${device.laptop} {
    display: flex;
    width: 100%;
    flex-direction: ${({ index }) => (index % 2 === 0 ? 'row' : 'row-reverse')};
    overflow: visible;
  }
`;

const ContentWrapper = styled.div`
  padding: 0 20px;
  position: relative;

  @media ${device.laptop} {
    padding-right: ${({ index }) => index % 2 > 0 ? 0 : '40px'};
    padding-left: ${({ index }) => index % 2 === 0 ? 0 : '40px'};
    display: flex;
    align-items: ${({ align }) => align};
  }
`;

const Content = styled.div`
  background-color: ${({ theme, light }) =>
    light ? theme.colors.primaryWhite : theme.colors.primaryBlack};
  color: ${({ theme, light }) =>
    light ? theme.colors.secondaryDark : theme.colors.primaryWhite};
  padding-bottom: 30px;

  h2 {
    padding: 50px 30% 0 30px;
    margin: 0;
    font-size: 30px;
    width: 100%;
  }

  p {
    padding: 20px 20px 20px 20px;
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.laptop} {
    width: calc(0.53 * 853px);
    height: calc(0.53 * 582px);
    padding-bottom: 40px;
    padding-right: calc(0.4 * 50px);
    padding-left: calc(0.4 * 160px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    
    h2 {
      font-size: calc(0.5 * 50px);
      line-height: calc(0.5 * 65px);
      padding-right: 0;
      padding-left: 0;
    }
    
    p {
      font-size: calc(0.5 * 25px);
      line-height: calc(0.5 * 38px);
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media ${device.laptopL} {
    width: calc(0.75 * 853px);
    height: calc(0.75 * 582px);
    padding-left: calc(0.6 * 160px);
    padding-right: calc(0.6 * 50px);
    
    h2 {
      font-size: calc(0.75 * 50px);
      line-height: calc(0.75 * 65px);
      padding-right: 0;
    }
    p {
      font-size: calc(0.75 * 25px);
      line-height: calc(0.75 * 38px);
      padding-right: 0;
    }
    
  }
  
  @media ${device.desktop} {
    width: 853px;
    height: 582px;
    padding-left: 160px;
    padding-right: 50px;
    
    h2 {
      font-size: 50px;
      line-height: 65px;
      padding-right: 0;
    }
    
    p {
      font-size: 25px;
      line-height: 38px;
    }
  }
  
  @media ${device.desktopL} {
    width: calc(1.3 * 853px);
    height: calc(1.3 * 582px);
    padding-right: calc(1.3 * 50px);
    padding-left: calc(1.3 * 160px);
    
    h2 {
      font-size: calc(1.3 * 50px);
      line-height: calc(1.3 * 65px);
    }
    
    p {
      font-size: calc(1.3 * 25px);
      line-height: calc(1.3 * 38px);
    }
  }
  

`;

const Image = styled.img`
  max-width: 100%;
  display: block;
  box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.5);

  @media ${device.laptop} {
    width: 50vw;
    height: calc(0.54 * 660px);
    z-index: ${({ align }) => (align === 'center' ? 9999 : -1)};
    z-index: 1000;
  }
  
  @media ${device.laptopL} {
    width: calc(0.75 * 960px);
    height: calc(0.75 * 660px);
  }
  
  @media ${device.desktop} {
    width: 960px;
    height: 660px;
  }
  
  @media ${device.desktopL} {
    width: 50%;
    height: calc(1.3 * 660px);
  }
 
  
`;

const ContentTile = ({ title, body, image, light, index }) => {
  const align = () => {
    if (index < 2) {
      return index % 2 > 0 ? 'flex-start' : 'flex-end';
    }
    if (index === 2) {
      return 'center';
    }
    if (index > 2) {
      return index % 2 === 0 ? 'flex-start' : 'flex-end';
    }
    return 'flex-start';
  };
  return (
    <StyledWrapper index={index}>
      <Image src={SECTION_IMAGES[image]} alt={title} align={align(index)} />

      <ContentWrapper index={index} align={align(index)}>
        <Content light={light}>
          <h2>{title}</h2>
          <p>{body}</p>
        </Content>
      </ContentWrapper>
    </StyledWrapper>
  );
};

export default ContentTile;

ContentTile.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  light: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

ContentTile.defaultProps = {
  light: false,
};

ContentTile.defaultProps = {};
