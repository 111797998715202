import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import content from '../../utils/content';
import device from '../../utils/device';
import withTranslations from '../../utils/withTranslations';

const StyledHeader = styled.h2`
  font-size: 32px;
  font-weight: bold;
  z-index: 999;
  margin-bottom: 5px;

  @media ${device.laptop} {
    color: ${({ theme, absolute }) =>
      absolute ? theme.colors.primaryWhite : theme.colors.primaryBlack};
    font-size: 22px;
  }
  
  @media ${device.laptopL} {
    font-size: 26px;
  }
  
  @media ${device.desktop} {
    font-size: 35px;
    line-height: 45px;
    max-width: 400px;
    margin-bottom: 10px;
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 35px);
    line-height: calc(1.3 * 45px);
  }
`;

const StyledSubheader = styled.p`
  font-size: 18px;
  letter-spacing: 0.81px;
  font-style: normal;
  z-index: 999;
  margin-top: 0;

  @media ${device.laptop} {
    color: ${({ theme, absolute }) =>
      absolute ? theme.colors.primaryWhite : theme.colors.primaryBlack};
    font-size: calc(0.53 * 21px);
    margin-bottom: 15px;
  }
  
  @media ${device.laptopL} {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  @media ${device.desktop} {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.45px;
    max-width: 360px;
    margin-bottom: 30px;
  }
  
  @media ${device.desktopL} {
    font-size: calc(1.3 * 21px);
    line-height: calc(1.3 * 30px);
    letter-spacing: calc(1.3 * 0.45px);
    max-width: initial;
  }
`;

const OfferFormHeader = ({ absolute, t }) => {
  return (
    <>
      <StyledHeader absolute={absolute}>{t(content.SEE_OUR_OFFER)}</StyledHeader>
      <StyledSubheader absolute={absolute}>
        {t(content.FILL_FORM_INFO)}
      </StyledSubheader>
    </>
  );
};

OfferFormHeader.propTypes = {
  absolute: PropTypes.bool,
};
OfferFormHeader.defaultProps = {
  absolute: false,
};

export default withTranslations(OfferFormHeader);
