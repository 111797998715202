import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import logo from 'assets/images/logo.svg';
import content from '../utils/content';
import device from '../utils/device';

const LogoImage = styled.img`
  width: ${({ large }) => (large ? '85%' : 'auto')};
  display: ${({ desktop }) => desktop && 'none'};

  ${({ header }) =>
    header && css`
      max-height: 50px;
    `}

  @media ${device.laptop} {
    ${({ header }) => (
      header && css`
        max-height: initial;
      `
    )}
  }
  
  @media ${device.laptop} { 
    width: ${({ xLarge }) => {
      if (xLarge) {
        return '240px';
      }
    }}; 
  } 
  
  @media ${device.laptopL} {
    width: ${({ xLarge }) => {
      if (xLarge) {
        return '260px';
      }
    }};
  } 
  
  @media ${device.desktop} {
    width: ${({ large, xLarge, medium }) => {
      if (large && !medium) {
        return '120%';
      }
      if (xLarge) {
        return '480px';
      }
      if (medium && large) {
        return '240%';
      }
      return '195px';
    }};
  }
  
  @media ${device.desktopL} {
    width: ${({ large, xLarge, medium }) => {
      if (large && !medium) {
        return '120%';
      }
      if (xLarge) {
        return '524px';
      }
      if (medium && large) {
        return '240%';
      }
      return '255px';
    }};
  }

`;

const Logo = ({ large, desktop, xLarge, medium, header }) => {
  return (
    <LogoImage
      src={logo}
      alt={content.LOGO_ALT}
      large={large}
      xLarge={xLarge}
      medium={medium}
      desktop={desktop}
      header={header}
    />
  );
};

Logo.propTypes = {
  medium: PropTypes.bool,
  large: PropTypes.bool,
  xLarge: PropTypes.bool,
  desktop: PropTypes.bool,
  header: PropTypes.bool,
};

Logo.defaultProps = {
  medium: false,
  large: false,
  xLarge: false,
  desktop: false,
  header: false,
};

export default Logo;
