import React from 'react';
import TextInput from '../../components/TextInput';
import content from '../../utils/content';
import Dropdown from '../../components/Dropdown';
import constants from '../../utils/constants';
import withTranslations from '../../utils/withTranslations';

const InputsSection = ({handleInputChange, isFormValid, t }) => {

  const onNameChange = (text) => handleInputChange(text, 'name')
  const onSurnameChange = text => handleInputChange(text, 'surname')
  const onEmailChange = text => handleInputChange(text, 'email')
  const onPhoneChange = text => handleInputChange(text, 'phone')
  const onLocationChange = text => handleInputChange(text, 'location')

  return (
    <div>
      <TextInput placeholder={t(content.NAME)} required onChange={onNameChange} isFormValid={isFormValid} />
      <TextInput placeholder={t(content.SURNAME)} required onChange={onSurnameChange} isFormValid={isFormValid} />
      <TextInput placeholder={t(content.EMAIL)} required type={constants.inputTypes.EMAIL} onChange={onEmailChange} isFormValid={isFormValid} />
      <TextInput placeholder={t(content.PHONE_NUMBER)} type={constants.inputTypes.PHONE} onChange={onPhoneChange} />
      {/* <Dropdown
        placeholder={`${t(content.LOCALIZATION)} ${t(content.CHOSE_FROM_THE_LIST_HINT)}`}
        onChange={onLocationChange}
        required
        isFormValid={isFormValid}
      /> */}
    </div>
  );
};

export default withTranslations(InputsSection);
