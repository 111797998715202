import React from 'react';
import styled from 'styled-components';
import Hero from '../Hero/Hero';
import OfferForm from '../OfferForm/OfferForm';

const StyledPageHeader = styled.div`
  position: relative;
`;

const PageHeader = () => {
  return (
    <StyledPageHeader>
      <Hero />
      <OfferForm absolute />
    </StyledPageHeader>
  );
};

export default PageHeader;
