import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import device from '../utils/device';

const StyledDescriptionWrapper = styled.div`
  @media ${device.laptop} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 13vw;
  }
`;

const DescriptionWrapper = ({ children }) => {
  return <StyledDescriptionWrapper>{children}</StyledDescriptionWrapper>;
};

DescriptionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DescriptionWrapper;
